import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
export default new Router({
    routes: [
        {path: '/', component: () => import('@/views/new-home'), meta: {title: ''}},
        {path: '/purchase', name: "purchase", component: () => import('@/views/purchase'),meta: {title: ''}},
        {path: '/purchaseDetails', name: "purchaseDetails", component: () => import('@/views/purchase/purchaseDetails'),meta: {title: ''}},
        {path: '/information', name: "information", component: () => import('@/views/information'),meta: {title: '-资讯中心'}},
        {path: '/informationDetails', name: "informationDetails",  component: () => import('@/views/information/informationDetails'),meta: {title: ''}},
        {path: '/specializedNewZone', name: "specializedNewZone", component: () => import('@/views/specialized-new-zone'),meta: {title: ''}},
        {path: '/specializedNewZoneTwo', name: "specializedNewZoneTwo", component: () => import('@/views/specialized-new-zone-two'),meta: {title: ''}},
        {path: '/specializedZoneYx', name: "specializedZoneYx", component: () => import('@/views/specialized-zone-yx'),meta: {title: ''}},
        {path: '/zone', name: "zone", component: () => import('@/views/zone/home'),meta: {title: '专区'}},
        {path: '/zonePurchase', name: "zonePurchase", component: () => import('@/views/zone/zone-purchase'),meta: {title: '专区'}},
        {path: '/zonePurchaseDetails', name: "zonePurchaseDetails", component: () => import('@/views/zone/purchaseDetails'),meta: {title: '专区'}},
        {path: '/solution', name: "solution", component: () => import('@/views/solution/home'),meta: {title: ''}},
        {path: '/zone-home', name: "zoneHome", component: () => import('@/views/zone-home/index'),meta: {title: ''}},
        {path: '/introduce', name: "introduce", component: () => import('@/views/introduce'),meta: {title: ''}},
        {path: '/member', name: "member", component: () => import('@/views/member'),meta: {title: ''}},
        {path: '/help', name: "help", component: () => import('@/views/help'),meta: {title: ''}},
        {path: '/helpDetails', name: "helpDetails", component: () => import('@/views/help/helpDetails'),meta: {title: ''}},
        {path: '/company-introduce', name: "companyIntroduce", component: () => import('@/views/zone/zone-introduce'),meta: {title: ''}},
        {path: '/contact', name: "contact", component: () => import('@/views/zone/zone-maincontact'),meta: {title: ''}},
        {path: '/specialized-new-zone', name: "specializedNewZone", component: () => import('@/views/specialized-new-zone'),meta: {title: '专区'}},
        {path: '/procurement', name: "procurement", component: () => import('@/views/report/procurement'),meta: {title: '采购看板'}},
        {path: '/supplier', name: "supplier", component: () => import('@/views/report/supplier'),meta: {title: '供应商看板'}},
        {path: '/platform', name: "platform", component: () => import('@/views/report/platform'),meta: {title: '平台看板'}},
        {path: '/reportTotal', name: "reportTotal", component: () => import('@/views/report/reportTotal'),meta: {title: '报表看板'}}
    ]
})
