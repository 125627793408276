<template>
  <div id="app" v-cloak v-title :data-title="meteTitle" :class="[{ 'app-mourn': isMourn }]">
    <!--  解决Vue同路由跳转页面参数变化后不重新加载  -->
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      meteTitle: "荷云采-湖南阳光招采平台",
      isMourn: false  // 是否哀悼模式
    }
  },
  metaInfo() {
    return {
      title: '百度站点官方认证',
      meta: [
        {name: 'baidu-site-verification', content: 'code-WqmwvAlhGL'},
      ],
    };
  },
  components: {}, created() {
    let _this = this
    this.$http.get(this.base + "/category/get.do", {
      params: {
        modelId: "CMS_CLASS"
      }
    }).then(function (data) {
      console.log(data)
      _this.isMourn = data?.data?.result?.isIssue == 0 ? true : false
    })
  }, watch: {
    '$route': 'toggleRouters'
  }, methods: {
    toggleRouters: function () {
    }
  }
}
</script>

<style>
.app-mourn {
  filter: grayscale(.95);
  -webkit-filter: grayscale(.95);
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=.95);
}

[v-cloak] {
  display: none;
}

html, body {
  font-size: 62.5%;
  background: #F4F4F4 !important;
}

app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/*全局居中*/
.cms-container {
  margin: 0 auto;
}

#app {
  background: #F4F4F4;
  /*height: 1979px;*/
}

.show-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.show-two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.left {
  float: left;
}

.right {
  float: right;
}

@media screen and (max-width: 576px) {
  .cms-container {
    width: 100%;
  }

  .cms-ssm-hidden {
    display: none !important;
  }
}

@media (min-width: 576px ) and (max-width: 768px) {
  .cms-container {
    width: 100%;
  }

  .cms-sm-hidden {
    display: none !important;
  }

  .cms-sm-show-one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .cms-sm-show-two-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .cms-container {
    width: 720px;
  }

  .cms-md-hidden {
    display: none !important;
  }

  .cms-md-show-one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .cms-md-show-two-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .cms-container {
    width: 960px;
  }

  .cms-lg-hidden {
    display: none !important;
  }

  .cms-lg-show-one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .cms-lg-show-two-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@media (min-width: 1200px) {
  .cms-container {
    width: 1160px;
  }

  .cms-xl-hidden {
    display: none !important;
  }

  .cms-xl-show-one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .cms-xl-show-two-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .zone-card {
    width: 1160px;
    margin: 0 auto;
    padding: 10px 30px;
    background: white;
  }
}

.red-span {
  color: red;
}

.qy-bg-color {
  background: rgb(0, 104, 183) !important;
}

.qy-color {
  color: rgb(0, 104, 183) !important;
}

</style>
