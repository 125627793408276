export default {
    /**
     * 福利商城跳转链接
     */
    goWelfareShop(company) {
        let width = document.documentElement.clientWidth;
        let isMobile = width < 576;
        if (company.companyId == '2220') {
            if (isMobile) {
                window.open("https://zzcffl.holdcg.com/h5/#/")
            } else {
                window.open("https://zzcffl.holdcg.com//#/mall")
            }
        }else if (company.companyId == '2114') {
            if (isMobile) {
                window.open("https://xtyyfl.holdcg.com/h5/#/")
            } else {
                window.open("https://xtyyfl.holdcg.com//#/mall")
            }
        } else {
            if (isMobile) {
                window.open("https://fl.holdcg.com/h5/#/")
            } else {
                window.open("https://fl.holdcg.com//#/mall")
            }
        }

    },
    /**
     * 直采商城
     * @param company 公司
     */
    goCollectiveShop(company) {
        let width = document.documentElement.clientWidth;
        let isMobile = width < 576;
        if (company.companyId == '2880') {
            if (isMobile){
                window.open("https://hngzymall.holdcg.com/h5/#/pages/index/index")
            }else {
                window.open("https://hngzymall.holdcg.com/#/coverPage")
            }
        } else if (company.companyId == '2725') {
            if (isMobile){
                window.open("https://hngzymall.holdcg.com/h5/#/pages/index/index")
            }else {
                window.open("https://xsjtmall.holdcg.com/#/coverPage")
            }
        } else if (company.companyId == '2114') {
            if (isMobile){
                window.open("https://xtyy.holdcg.com/h5/#/pages/index/index")
            }else {
                window.open("https://xtyy.holdcg.com/#/coverPage")
            }
        }else if (company.companyId == '2220') {
            if (isMobile){
                window.open("https://zzcf.holdcg.com/h5/#/pages/index/index")
            }else {
                window.open("https://zzcf.holdcg.com/#/coverPage")
            }
        } else {
            if (isMobile){
                window.open("https://mall.holdcg.com/h5/#/pages/index/index")
            }else {
                window.open("https://mall.holdcg.com/#/coverPage")
            }
        }

    },
}
