import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import { Message } from "element-ui";
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/commoon/css/style.css'
import 'element-ui/lib/theme-chalk/display.css';
import {BootstrapVue, IconsPlugin, CarouselPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import axios from 'axios'
import router from './router'
import moment from 'moment'//日期格式化
import VueMeta from "vue-meta";
import * as echarts from 'echarts'
import '@/utils/table2excel.js' // global table2excel
import utils from '@/utils/util.js' // global table2excel
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$utils = utils
Vue.prototype.cmsMessage = Message
Vue.prototype.$moment = moment;
axios.defaults.withCredentials = true
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(CarouselPlugin);
let base = "";
let manager = "";
let member = "";
let memberThree="";
let fileUrl = "";
let cgzxUrl = "";
if ('localhost' == location.hostname) {
    //这是本地起的服务,后端取6850
    base = "http://localhost:6850/cms";
    manager = "http://localhost:6850/cms";
    member = "http://localhost:6850";
    memberThree = "https://" + "old2.holdcg.com/";
    cgzxUrl = "http://localhost:81";
} else if ("www.holdcg.com" === window.location.host) {
    //生产环境是https的单独拿出来
    base = "https://old2.holdcg.com/cms";
    manager = "https://old2.holdcg.com/cms";
    memberThree = "https://" + window.location.host;
    member = "https://old2.holdcg.com/";
    fileUrl = "https://" + window.location.host + "/zuul/cms";
    cgzxUrl = "http://cgzx.holdcg.com";
} else {
    //测试环境用这个http地址加端口
    let protocol = window.location.protocol;
    base = protocol + "//" + window.location.host + "/cms";
    manager = protocol + "//" + window.location.host + "/cms";
    member = protocol + "//" + window.location.host;
    // /*处理zuul转发中文乱码*/
    fileUrl = protocol + "//" + window.location.host + "/zuul/cms";
    let host = window.location.hostname.toString();
    let hostUrl = "";
    if (host.indexOf("200") != -1 || host.indexOf("uat")) {
        hostUrl = "10.0.50.204";
    } else if(host.indexOf("158") != -1){
        hostUrl = "10.0.50.159";
    }
    cgzxUrl = "http://" + hostUrl + ":1024";
}
// base = "/dev-api/cms";
// manager = "/dev-api/cms";
// member = "/dev-api";
// cgzxUrl = "http://127.0.0.1";

// base = "https://www.holdcg.com/cms";
// manager = "https://www.holdcg.com/cms";
// member = "https://www.holdcg.com";
// fileUrl = "https://www.holdcg.com/zuul/cms";

// base = "http://localhost:6850/cms";
// manager = "http://localhost:6850/cms";
// member = "http://localhost:6850";
// cgzxUrl = "http://localhost:81";

Vue.prototype.base = base;
/*网站title*/
Vue.prototype.title = "荷云采-湖南阳光招采平台";
Vue.prototype.manager = manager;
Vue.prototype.member = member;
Vue.prototype.memberThree = memberThree;
Vue.prototype.cgzxUrl = cgzxUrl;
Vue.use(ElementUI);
Vue.use(Antd);
Vue.use(VueMeta);
import VueRouter from 'vue-router'

Vue.use(VueRouter);
/* eslint-disable no-new */
Vue.directive('title', {
    inserted: function (el, binding) {
        document.title = el.dataset.title
    }
})
new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
